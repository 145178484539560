<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Cristian Valencia Agudelo                                     ###### -->
<!-- ###### @date: Enero 2025                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="d-flex">
      <SubMenu :nameModule="nameModule" :subMenu="subMenu" />
  
      <!-- Seccion para cargar el contenido de la navegación del modulo-->
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
    </div>
  </template>
   
   
   <!-- #################################################################################### -->
   <!-- ###### Sección de Scripts                                                     ###### -->
   <!-- #################################################################################### -->
  <script>
  import { mapState } from "vuex";
  import SubMenu from '@/components/SubMenu.vue';
  import { Role } from "@/router/role.js";
  
  export default {
    name: 'ExpedienteMain',
    components: { SubMenu },
    data: () => ({
      tab: null,
      nameModule: "Expediente",
      userRoles: {},
      roles: Role,
      subMenu: [
        {
          name: "Bandeja",
          icon: "note_add",
          rol: [Role.Expediente_Administrador, Role.Expediente_Digitalizador],
          link: { name: 'modules.expedienteMain.bandeja' }
        },
        // {
        //   name: "Digitalizador",
        //   icon: "fact_check",
        //   rol: Role.Expediente_Digitalizador,
        //   link: { name: 'modules.expedienteMain.digitalizador' }
        // },
        {
          name: "Expedientes",
          icon: "create_new_folder",
          rol: [Role.Expediente_Administrador, Role.Expediente_Auditor, Role.Expediente_Radicador],
          link: { name: 'modules.expedienteMain.expedientes' }
        },
        {
          name: "Parametrización",
          icon: "document_scanner",
          rol: [Role.Expediente_Administrador],
          link: { name: 'modules.expedienteMain.parametrizacion' }
        },
        // {
        //   name: "Expediente",
        //   icon: "fact_check",
        //   rol: Role.Expediente_Digitalizador,
        //   link: { name: 'modules.expedienteMain.expediente' }
        // }
      ],
  
    }),
    computed: {
      ...mapState(["auth"]),
    },
    created() {
      this.userRoles = this.auth.roles;
    },
  }
  </script>
   
   <!-- #################################################################################### -->
   <!-- ###### Sección de Styles                                                      ###### -->
   <!-- #################################################################################### -->
  <style scoped></style>